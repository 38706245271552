import { handleErrorWithSentry, Replay } from "@sentry/sveltekit";
import * as Sentry from "@sentry/sveltekit";

Sentry.init({
  dsn: "https://667c1cc41cab476eb0f833d905edeeae@o4505596250619904.ingest.sentry.io/4505596251930624",
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Replay()],
});

// @ts-ignore
export const handleError = handleErrorWithSentry(({ error, event }) => {
  console.error("An error occurred on the client side:", error, event);
});
